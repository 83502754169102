import { jobTypes } from "../helpers/constant";

// for checking non-existent path
const check404 = (pathArr, jobTypes) => {
  for (const path of pathArr) {
    if (path === "jobs") {
      return false;
    }

    const cleanedPath = path.replaceAll("-", " ");

    // checks for chinese character, and returns all chinese character into a single string
    const hanWords =
      cleanedPath.match(
        /[\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF]+(?=\W*[a-zA-Z])/g
      ) || [];

    if (Array.isArray(hanWords) && hanWords.length > 0) {
      return false;
    }

    // if there is no chinese characters, only proceed to match for roman characters
    const words = cleanedPath.match(/\b\w+\b/g) || [];

    if (words.length > 1) {
      if (
        cleanedPath.startsWith("in") ||
        cleanedPath.startsWith("jobs") ||
        cleanedPath.endsWith("jobs")
      ) {
        return false;
      }
    }

    for (const type of jobTypes) {
      if (path.includes(type.slug)) {
        return false;
      }
    }
  }

  return true;
};

const checkCompany404 = (pathArr, companySizesList) => {
  for (const path of pathArr) {
    const cleanedPath = path.replaceAll("-", " ");

    if (cleanedPath.startsWith("in")) {
      return false;
    }

    for (const size of companySizesList) {
      if (path.includes(size.slug)) {
        return false;
      }
    }
  }

  return true;
};

const getTitle = (filterParams, specialisationList, stateRegions) => {
  let subheaderTitle = null;

  if (Object.keys(filterParams).length > 1 || filterParams.keyword) {
    return null;
  }

  if (filterParams.trackIds) {
    const specialisation = filterParams.trackIds.map((id) => {
      return specialisationList.find((spec) =>
        spec.subSpecialisations.some((sub) => sub.id === id)
      );
    });
    return specialisation.length > 1 ? null : { title: specialisation[0].name };
  }

  if (filterParams.stateRegions) {
    const key = "state";
    return { title: filterParams.stateRegions, key };
  }

  if (filterParams.jobTypeIds) {
    const jobType = filterParams.jobTypeIds.map((id) => {
      return jobTypes.find((jobType) => jobType.id === id);
    });
    return jobType.length > 1 ? null : { title: jobType[0].jobType };
  }

  return subheaderTitle;
};

const convertUrlToIds = (query, specialisationList, stateRegionsList) => {
  const result = {};

  const slugArr = query.filter;

  // define query params
  const specQuery = query.specialisation;
  const state = query.state;
  const jobType = query["job-type"];
  const experience = query.experience;
  const expectedSalary = query.salary;

  // check if experience exists as a array or a single value
  if (experience) {
    result.experienceIds = Array.isArray(experience)
      ? experience.map(Number)
      : [Number(experience)];
  }

  // check if expected salary exist
  if (expectedSalary) {
    result.expectedSalary = Number(expectedSalary);
  }

  // remove "in-" or "jobs-in-" from slug
  const cleanedSlugs = slugArr?.map((slug) =>
    slug.replace(/^(in-|jobs-in-)/, "")
  );

  // create map for slug to specialisation, sub specialisation and state
  const slugToSpecialisation = new Map();
  const slugToSubSpecialisation = new Map();
  const slugToState = new Map();
  const slugToJobType = new Map();

  // loop through specialisation list and sub specialisation list to create map
  specialisationList.forEach((spec) => {
    slugToSpecialisation.set(spec.slug, spec);
    spec.subSpecialisations.forEach((sub) => {
      slugToSubSpecialisation.set(sub.slug, sub);
    });
  });

  // loop through state region list to create map
  stateRegionsList.forEach((region) => {
    slugToState.set(region.slug, region);
  });

  // loop through job type list to create map
  jobTypes.forEach((type) => {
    slugToJobType.set(type.slug, type);
  });

  // loop through current slug to check if there any filter applied
  cleanedSlugs?.forEach((slug, index) => {
    // check if any slug has match with any filter
    const specialisationMatch = slugToSpecialisation.get(slug);
    const subSpecialisationMatch = slugToSubSpecialisation.get(slug);
    const stateMatch = slugToState.get(slug);
    const jobTypeMatch = slugToJobType.get(slug.replace("-jobs", ""));

    // if no match found, add keyword to keyword
    if (
      !jobTypeMatch &&
      !stateMatch &&
      !specialisationMatch &&
      !subSpecialisationMatch &&
      slug.includes("-jobs")
    ) {
      result.keyword = slug.replace(/-jobs/g, "").replace(/-/g, " ");
    }

    const keywordExist = Object(result?.keyword).length > 0;

    //if list of specialisations exist, add it to trackIds
    //if subpec is the same as spec, add subspec instead of spec to trackIds
    //if spec match, add all subspecs under spec to trackIds
    //if subspec match, add the one subspec to trackIds
    if (specQuery) {
      result.trackIds = Array.isArray(specQuery)
        ? specQuery.map(Number)
        : [Number(specQuery)];
    } else if (
      subSpecialisationMatch &&
      subSpecialisationMatch?.slug === specialisationMatch?.slug &&
      ((keywordExist && index === 2) || (!keywordExist && index === 1))
    ) {
      result.trackIds = [subSpecialisationMatch?.id];
    } else if (specialisationMatch) {
      result.trackIds = [
        ...new Set(specialisationMatch.subSpecialisations.map((sub) => sub.id)),
      ];
    } else if (subSpecialisationMatch) {
      result.trackIds = [subSpecialisationMatch.id];
    }

    // if state exist, add it to stateRegions
    if (state) {
      result.stateRegions = Array.isArray(state) ? state : [state];
    } else if (stateMatch) {
      result.stateRegions = [stateMatch.state];
    }

    // if job type exist, add it to jobTypeIds
    if (jobType) {
      result.jobTypeIds = Array.isArray(jobType)
        ? jobType.map(Number)
        : [Number(jobType)];
    } else if (jobTypeMatch) {
      result.jobTypeIds = [jobTypeMatch.id];
    }
  });

  return result;
};

const convertPageToString = (input) => {
  if (!input.page) {
    return null;
  }
  const pageAmount = 30;
  const pageCount = Number(input.page) * pageAmount;

  return btoa(pageCount);
};

const checkNoIndex = (input, filterParams) => {
  if (!input || (!filterParams?.keyword && !filterParams?.trackIds)) return;

  if (
    (input.experience && input.experience.length > 0) ||
    (input.salary && input.salary.length > 0) ||
    (input.specialisation && input.specialisation.length > 0) ||
    (filterParams?.keyword?.length > 0 && filterParams?.trackIds?.length > 0)
  ) {
    return true;
  }
  return false;
};

const convertCompanyUrlToIds = (
  query,
  industriesList,
  locationList,
  companySizesList
) => {
  if (!query) return null;

  const result = {};

  const slugArr = query.filter;

  // define query params
  const industryQuery = query.industry_ids;
  const locationQuery = query.state_regions;
  const companySizeQuery = query.company_sizes;

  // // remove "in-" or "jobs-in-" from slug
  // const cleanedSlugs = slugArr?.map((slug) => slug.replace(/^(in-|with-)/, ""));

  // // create map for slug to specialisation, sub specialisation and state
  // const slugToIndustry = new Map();
  // const slugToLocation = new Map();
  // const slugToCompanySize = new Map();

  // // loop through state region list to create map
  // industriesList.forEach((region) => {
  //   slugToIndustry.set(region.slug, region);
  // });

  // // loop through state region list to create map
  // locationList.forEach((region) => {
  //   slugToLocation.set(region.slug, region);
  // });

  // // loop through job type list to create map
  // companySizesList.forEach((type) => {
  //   slugToCompanySize.set(type.slug, type);
  // });

  // // loop through current slug to check if there any filter applied
  // cleanedSlugs?.forEach((slug, index) => {
  //   // check if any slug has match with any filter
  //   const industryMatch = slugToIndustry.get(slug);
  //   const locationMatch = slugToLocation.get(slug);
  //   const companySizeMatch = slugToCompanySize.get(slug);

  //   // if industry exist, add it to industry
  //   if (industryMatch) {
  //     result.industry_ids = [parseInt(industryMatch.id)];
  //   }

  //   // if state exist, add it to stateRegions
  //   if (locationMatch) {
  //     result.state_regions = [locationMatch.state];
  //   }

  //   // if company size exist, add it to company size
  //   if (companySizeMatch) {
  //     result.company_sizes = [parseInt(companySizeMatch.id)];
  //   }
  // });

  if (industryQuery) {
    result.industry_ids = Array.isArray(industryQuery)
      ? industryQuery.map((id) => parseInt(id))
      : [parseInt(industryQuery)];
  }

  if (locationQuery) {
    result.state_regions = Array.isArray(locationQuery)
      ? locationQuery
      : [locationQuery];
  }

  if (companySizeQuery) {
    result.company_sizes = Array.isArray(companySizeQuery)
      ? companySizeQuery.map((id) => parseInt(id))
      : [parseInt(companySizeQuery)];
  }

  return result;
};

export {
  check404,
  checkCompany404,
  checkNoIndex,
  convertCompanyUrlToIds,
  convertPageToString,
  convertUrlToIds,
  getTitle,
};
